import React, { Suspense } from "react";
import { Routes, Route, Outlet, Navigate } from "react-router-dom";
import FallbackSpinner from "./@core/components/spinner";

import AuthGuard from "src/@core/components/auth/AuthGuard";
import UserLayout from "./layouts/UserLayout";
import BlankLayout from "./@core/layouts/BlankLayout";
// import BlankLayoutWithAppBar from './@core/layouts/BlankLayoutWithAppBar'
import AclGuard from "./@core/components/auth/AclGuard";
import GuestGuard from "./@core/components/auth/GuestGuard";
import { defaultACLObj } from "./configs/acl";
import AuthLayout from "./layouts/AuthLayout";
import SubUserLayout from "./layouts/SubUserLayout";

//website
const AppBarPage = React.lazy(() => import("./pages/app-bar"));
const ClientCategoryPage = React.lazy(() => import("./pages/client-category"));
const SuccessStoriesPage = React.lazy(() => import("./pages/success-stories"));
const ContactUsPage = React.lazy(() => import("./pages/contact-us"));
const BlogPage = React.lazy(() => import("./pages/blog"));
const CareerPage = React.lazy(() => import("./pages/career"));
const ITSolutionPage = React.lazy(() =>
  import("./pages/it-solutions/it-solution-dropdown/it-solution")
);
const ITSolutionSubPage = React.lazy(() =>
  import("./pages/it-solutions/it-solution-dropdown/it-solution-sub")
);
const ITSolutionSubListPage = React.lazy(() =>
  import("./pages/it-solutions/it-solution-dropdown/it-solution-sub-list")
);
const ITSolutionOverviewPage = React.lazy(() =>
  import("./pages/it-solutions/it-solution-detail-pages/it-solution-overview")
);
const ITSolutionSubOverviewPage = React.lazy(() =>
  import(
    "./pages/it-solutions/it-solution-detail-pages/it-solution-subOverview"
  )
);
const ITSolutionOverviewTitle = React.lazy(() =>
  import(
    "./pages/it-solutions/it-solution-detail-pages/it-solution-overview-mainTitle"
  )
);
const ITSolutionOverviewDownload = React.lazy(() =>
  import(
    "./pages/it-solutions/it-solution-detail-pages/it-solution-overview-download"
  )
);
const ITSolutionOverViewPlan = React.lazy(() =>
  import(
    "./pages/it-solutions/it-solution-detail-pages/it-solution-overview-plan"
  )
);
const ITSolutionOverviewFeaturesTitle = React.lazy(() =>
  import(
    "./pages/it-solutions/it-solution-detail-pages/it-solution-overview-features-title"
  )
);
const ITSolutionOverviewFeatures = React.lazy(() =>
  import(
    "./pages/it-solutions/it-solution-detail-pages/it-solution-overview-features"
  )
);
const ITSolutionFeaturesPage = React.lazy(() =>
  import("./pages/it-solutions/it-solution-detail-pages/it-solution-features")
);
const ITSolutionFeaturesTitle = React.lazy(() =>
  import(
    "./pages/it-solutions/it-solution-detail-pages/it-solution-features-title"
  )
);
const ITSolutionSubFeaturesPage = React.lazy(() =>
  import(
    "./pages/it-solutions/it-solution-detail-pages/it-solution-subFeatures"
  )
);
const ITSolutionAdditionalFeaturesPage = React.lazy(() =>
  import(
    "./pages/it-solutions/it-solution-detail-pages/it-solution-additional-features"
  )
);
const ITSolutionAdditionalFeaturesList = React.lazy(() =>
  import(
    "./pages/it-solutions/it-solution-detail-pages/it-solution-additional-features-list"
  )
);
//admin
const HomePage = React.lazy(() => import("./pages/home"));
const DashboardPage = React.lazy(() => import("./pages/dashboard"));
const LoginPage = React.lazy(() => import("./pages/login"));
const ForgotPassword = React.lazy(() => import("./pages/login/forgotpassword"));
const Page401 = React.lazy(() => import("./pages/401"));
const Page404 = React.lazy(() => import("./pages/404"));

const Guard = ({ children, authGuard, guestGuard }) => {
  if (guestGuard) {
    return <GuestGuard fallback={<FallbackSpinner />}>{children}</GuestGuard>;
  } else if (!guestGuard && !authGuard) {
    return <>{children}</>;
  } else {
    return <AuthGuard fallback={<FallbackSpinner />}>{children}</AuthGuard>;
  }
};

function App() {
  const aclAbilities = defaultACLObj;

  return (
    <Suspense fallback={<FallbackSpinner />}>
      <AclGuard aclAbilities={aclAbilities}>
        <Routes>
          <Route
            element={
              <BlankLayout>
                <Outlet />
              </BlankLayout>
            }
          >
            <Route path="/401" element={<Page401 />} />
            <Route path="/404" element={<Page404 />} />

            <Route
              element={
                <AuthLayout>
                  <Outlet />
                </AuthLayout>
              }
            >
              <Route
                element={
                  <Guard guestGuard>
                    <Outlet />
                  </Guard>
                }
              >
                <Route path="/login" element={<LoginPage />}></Route>
                <Route path="/forgot-password" element={<ForgotPassword />} />
              </Route>
            </Route>
          </Route>

          <Route
            element={
              <UserLayout>
                <Outlet />
              </UserLayout>
            }
          >
            <Route
              element={
                <Guard authGuard>
                  <Outlet />
                </Guard>
              }
            >
              <Route path="" element={<HomePage />} />
              <Route path="/dashboard" element={<DashboardPage />} />
              <Route path="/app-bar" element={<AppBarPage />} />
              <Route path="/success-stories" element={<SuccessStoriesPage />} />
              <Route path="/it-solutions" element={<ITSolutionPage />} />
              <Route
                path="/it-solution-sub/:id"
                element={<ITSolutionSubPage />}
              />
              <Route
                path="/it-solution-sublist/:id"
                element={<ITSolutionSubListPage />}
              />
              <Route path="/client-category" element={<ClientCategoryPage />} />
              <Route path="/contact-us" element={<ContactUsPage />} />
              <Route path="/career" element={<CareerPage />} />
              <Route path="/blog" element={<BlogPage />} />
            </Route>
          </Route>
          <Route
            element={
              <SubUserLayout>
                <Outlet />
              </SubUserLayout>
            }
          >
            <Route
              element={
                <Guard authGuard>
                  <Outlet />
                </Guard>
              }
            >
              <Route path="" element={<HomePage />} />
              <Route path="/dashboard" element={<DashboardPage />} />
              <Route
                path="/it-solution-overview/:id"
                element={<ITSolutionOverviewPage />}
              />
              <Route
                path="/it-solution-sub-overview/:id"
                element={<ITSolutionSubOverviewPage />}
              />
              <Route
                path="/it-solution-overview-title/:id"
                element={<ITSolutionOverviewTitle />}
              />
              <Route
                path="/it-solution-overview-download/:id"
                element={<ITSolutionOverviewDownload />}
              />
              <Route
                path="/it-solution-overview-plan/:id"
                element={<ITSolutionOverViewPlan />}
              />
              <Route
                path="/it-solution-overview-features-title/:id"
                element={<ITSolutionOverviewFeaturesTitle />}
              />
              <Route
                path="/it-solution-overview-features/:id"
                element={<ITSolutionOverviewFeatures />}
              />
              <Route
                path="/it-solution-features/:id"
                element={<ITSolutionFeaturesPage />}
              />
              <Route
                path="/it-solution-features-title/:id"
                element={<ITSolutionFeaturesTitle />}
              />
              <Route
                path="/it-solution-sub-features/:id"
                element={<ITSolutionSubFeaturesPage />}
              />
              <Route
                path="/it-solution-additional-features/:id"
                element={<ITSolutionAdditionalFeaturesPage />}
              />
              <Route
                path="/it-solution-additional-features-list/:id"
                element={<ITSolutionAdditionalFeaturesList />}
              />
            </Route>
          </Route>

          {/* If no route found redirect it to --> /404 */}
          <Route path="*" element={<Navigate to="/404" replace />} />
        </Routes>
      </AclGuard>
    </Suspense>
  );
}

export default App;
